import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './Header.scss';
import Logo from '../assets/sigrid-tech.svg';

const Header: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const handleMenuItemClick = (sectionId: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setDrawerOpen(false); 
    }
  };

  const renderMenuItems = () => (
    <>
      <ListItem button component="a" href="#home" onClick={handleMenuItemClick("#home")}>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component="a" href="#why-choose-us" onClick={handleMenuItemClick("#why-choose-us")}>
        <ListItemText primary="Por que nos escolher" />
      </ListItem>
      <ListItem button component="a" href="#services" onClick={handleMenuItemClick("#services")}>
        <ListItemText primary="Nossos serviços" />
      </ListItem>
      <ListItem button component="a" href="#contact" onClick={handleMenuItemClick("#contact")}>
        <ListItemText primary="Contato" />
      </ListItem>
    </>
  );

  return (
    <AppBar position="static" className="header-appbar">
      <Toolbar>
        <img src={Logo} alt="Sigrid Tech Logo" className="logo" />
        <div className="spacer"></div>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              PaperProps={{ 'aria-label': 'Navegação principal' }}
            >
              <List>
                {renderMenuItems()}
              </List>
            </Drawer>
          </>
        ) : (
          renderMenuItems()
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
