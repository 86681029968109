import React from 'react';
import './Footer.scss';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <Container maxWidth="lg">
        <Typography variant="body1" color="textSecondary" align="center">
          &copy; {new Date().getFullYear()} Sigrid Tech. Todos os direitos reservados.
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
