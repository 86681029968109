import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './Contact.scss';

const Contact: React.FC = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Aqui você pode adicionar a lógica para envio do formulário, como uma chamada de API
    console.log("Formulário enviado");
    setFormSubmitted(true);
  };

  const whatsappMessage = encodeURIComponent("Olá, estou entrando em contato através do site e gostaria de saber mais sobre os serviços da Sigrid Tech.");

  return (
    <section id="contact" className="contact-section">
      <Container maxWidth="md">
        <Typography variant="h4" className='section-title' gutterBottom>
          Contato
        </Typography>
        <Typography variant="body1" gutterBottom>
          Fique à vontade para entrar em contato conosco através dos seguintes meios:
        </Typography>

        <Grid container spacing={3} justifyContent="center" className="contact-info-grid">
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" justifyContent="center" className="contact-info">
              <EmailIcon color="primary" />
              <Typography variant="body1" style={{ marginLeft: '8px' }}>
                <a href="mailto:contato@sigridtech.com">contato@sigridtech.com</a>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" justifyContent="center" className="contact-info">
              <WhatsAppIcon style={{ color: '#25D366' }} />
              <Typography variant="body1" style={{ marginLeft: '8px' }}>
                <a href={`https://wa.me/5511918490207?text=${whatsappMessage}`}  target="_blank" rel="noopener noreferrer">+55 11 91849-0207</a>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h5" gutterBottom className="form-title" style={{ marginTop: '2rem' }}>
          Ou envie-nos uma mensagem diretamente:
        </Typography>

        <Paper elevation={3} className="contact-form-paper">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                  required
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Assunto"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Mensagem"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" className="submit-button">
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
          {formSubmitted && (
            <Typography variant="body1" color="success" className="success-message">
              Obrigado por entrar em contato! Responderemos em breve.
            </Typography>
          )}
        </Paper>
      </Container>
    </section>
  );
};

export default Contact;
