import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import './Services.scss';

import securityImage from '../assets/consultoria_cyber.webp';
import cloudImage from '../assets/consultoria_cloud.webp';
import developmentImage from '../assets/desenvolvimento_software.webp';
import architectureImage from '../assets/criacao_arquitetura.webp';
import secureDevelopmentImage from '../assets/desenvolvimento_seguro.webp';
import devOpsImage from '../assets/consultoria_devops.webp';

const Services: React.FC = () => {
  return (
    <section id="services" className="services-section">
      <Container maxWidth="lg">
        <Typography variant="h4" className="section-title" gutterBottom>
          Nossos Serviços
        </Typography>
        <Typography variant="body1" className="intro-text">
          Uma visão geral dos serviços que oferecemos.
        </Typography>

        <Grid container spacing={4} className="services-grid">
          <Grid item xs={12} md={4}>
            <div className="service-box">
              <img src={securityImage} alt="Consultoria em Segurança" className="service-image" />
              <Typography variant="h6" className="service-title">
                Consultoria em Segurança
              </Typography>
              <Typography variant="body2" className="service-description">
                Protegemos suas informações com as melhores práticas e soluções em cibersegurança.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="service-box">
              <img src={cloudImage} alt="Consultoria em Cloud" className="service-image" />
              <Typography variant="h6" className="service-title">
                Consultoria em Cloud
              </Typography>
              <Typography variant="body2" className="service-description">
                Ajudamos a migrar e gerenciar sua infraestrutura na nuvem, garantindo escalabilidade e segurança.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="service-box">
              <img src={developmentImage} alt="Desenvolvimento de Software" className="service-image" />
              <Typography variant="h6" className="service-title">
                Desenvolvimento de Software
              </Typography>
              <Typography variant="body2" className="service-description">
                Criamos soluções tecnológicas personalizadas que atendem às necessidades específicas do seu negócio.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="service-box">
              <img src={architectureImage} alt="Criação de Arquitetura" className="service-image" />
              <Typography variant="h6" className="service-title">
                Criação de Arquitetura
              </Typography>
              <Typography variant="body2" className="service-description">
                Desenvolvemos arquiteturas robustas e escaláveis para suportar o crescimento e a segurança da sua empresa.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="service-box">
              <img src={secureDevelopmentImage} alt="Desenvolvimento Seguro" className="service-image" />
              <Typography variant="h6" className="service-title">
                Desenvolvimento Seguro
              </Typography>
              <Typography variant="body2" className="service-description">
                Integramos segurança em cada etapa do desenvolvimento para garantir que suas aplicações estejam protegidas.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="service-box">
              <img src={devOpsImage} alt="Consultoria em DevOps" className="service-image" />
              <Typography variant="h6" className="service-title">
                Consultoria em DevOps
              </Typography>
              <Typography variant="body2" className="service-description">
                Ajudamos a integrar as operações de desenvolvimento e TI para acelerar a entrega de software, garantindo eficiência e qualidade.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Services;
