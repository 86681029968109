import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import './WhyChooseUs.scss';
import reliabilityImage from '../assets/reliability.webp'; 
import innovationImage from '../assets/innovation.webp'; 
import securityImage from '../assets/security.webp'; 

const WhyChooseUs: React.FC = () => {
  return (
    <section id="why-choose-us" className="why-choose-us-section">
      <Container maxWidth="lg">
        <Typography variant="h4" className="section-title" gutterBottom>
          Por que nos escolher?
        </Typography>
        <Typography variant="body1" className="intro-text">
          Descubra as razões pelas quais a Sigrid Tech é a escolha certa para sua empresa.
        </Typography>

        <Grid container spacing={4} className="reasons-section">
          <Grid item xs={12} md={4}>
            <div className="reason-box">
              <img src={reliabilityImage} alt="Confiabilidade" className="reason-image" />
              <Typography variant="h6" className="reason-title">
                Confiabilidade
              </Typography>
              <Typography variant="body2" className="reason-description">
                Garantimos soluções estáveis e de alta qualidade que você pode confiar.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="reason-box">
              <img src={innovationImage} alt="Inovação" className="reason-image" />
              <Typography variant="h6" className="reason-title">
                Inovação
              </Typography>
              <Typography variant="body2" className="reason-description">
                Estamos sempre na vanguarda da tecnologia, trazendo inovação para o seu negócio.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="reason-box">
              <img src={securityImage} alt="Segurança" className="reason-image" />
              <Typography variant="h6" className="reason-title">
                Segurança
              </Typography>
              <Typography variant="body2" className="reason-description">
                A segurança é nossa prioridade, protegendo seus dados e operações.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default WhyChooseUs;
