// src/App.tsx
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import WhyChooseUs from './components/WhyChooseUs';
import Services from './components/Services';
import Contact from './components/Contact';
import './styles/main.scss';

const App: React.FC = () => {
  return (
    <div>
      <Header />
      <main>
        <Home />
        <WhyChooseUs />
        <Services />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default App;
