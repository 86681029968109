import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import './Home.scss';
import CompanyOverviewImage from '../assets/company_overview.webp'; 

const Home: React.FC = () => {
  return (
    <section id="home" className="home-section">
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2" className="highlight-title" gutterBottom>
              Bem-vindo à Sigrid Tech
            </Typography>
            <Typography variant="h5" className="subtitle" gutterBottom>
              Inovando com segurança e confiabilidade
            </Typography>
            <Typography variant="body1" className="intro-text">
              Na Sigrid Tech, nosso compromisso é transformar a maneira como as empresas interagem com a tecnologia. 
              Somos especializados em criar soluções tecnológicas que não apenas atendem, mas superam as expectativas dos nossos clientes.
              Com um foco claro em segurança, inovação e escalabilidade, oferecemos serviços que abrangem desde a consultoria em segurança cibernética 
              até o desenvolvimento de software sob medida e soluções de cloud computing.
            </Typography>
            <Typography variant="body1" className="intro-text">
              Nossa missão é capacitar empresas a operarem com máxima eficiência e segurança, garantindo que suas operações 
              estejam protegidas contra ameaças digitais enquanto aproveitam as últimas inovações tecnológicas. 
              Acreditamos que a tecnologia deve ser uma força habilitadora, e não um obstáculo, e trabalhamos incessantemente 
              para garantir que nossos clientes alcancem seus objetivos de negócios com confiança e tranquilidade.
            </Typography>
            <Typography variant="body1" className="intro-text">
              Na Sigrid Tech, valorizamos a confiança e a parceria. Nossa equipe de especialistas está sempre disponível para 
              oferecer suporte e orientação, desde a fase de planejamento até a implementação e manutenção. 
              Com uma abordagem personalizada, garantimos que cada solução seja adaptada às necessidades únicas de cada cliente.
            </Typography>
            <Button variant="contained" href="#contact" color="primary" className="cta-button">
              Saiba mais
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={CompanyOverviewImage} alt="Visão geral da Sigrid Tech" className="overview-image" />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Home;
